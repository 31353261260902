import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS, COMMON_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getFeesList = (payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getFeesByCustomerId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&customerId=${payload.customerId}&formId=${payload.formId || 0}`)
            .then((res) => {
              const data = {
                pageNo: payload.pageNumber,
                list: res.data
              }
                dispatch({
                    type: SCHOOL_ACTIONS.FEE_LIST,
                    data: data,
                });
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
              dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
              //dispatch({ type: SCHOOL_ACTIONS.FEE_LIST, data: {isStudentFormListLoaded : true}});
            });
    };
};

export const getFeesCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getTotalInvoiceCountByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`
      )
      .then((res) => {
        dispatch({
          type: SCHOOL_ACTIONS.FEE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SCHOOL_ACTIONS.FEE_LIST_PAGE_RESET });
  };
};

export const getInvoiceProforma = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getRegistrationInvoiceProforma?relationshipId=${payload.relationshipId || 0}&orgRegistrationId=${payload.orgRegistrationId || 0}&customerId=${payload.customerId || 0}`)
      .then((res) => {
        dispatch({
            type: SCHOOL_ACTIONS.INVOICE_PROFORMA_LIST,
            data: res.data,
        });
      })
      .catch((err) => {
          if (!err.__isRetryRequest) {
              showToasterMessage({
                  messageType: "error",
                  description:
                      lang[((err.response || {}).data || {}).message] ||
                      "Some error occurred",
              });
          }
      })
      .finally(() => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};


export const getInvoiceByProformaId = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getInvoiceByProformaId?relationshipId=${payload.relationshipId || 0}&proformaId=${payload.proformaId || 0}`)
      .then((res) => {
        dispatch({
            type: SCHOOL_ACTIONS.INVOICE_DETAILS,
            data: res.data,
        });
        
        if(payload.callBack){
          payload.callBack(res.data);
        }
      })
      .catch((err) => {
          if (!err.__isRetryRequest) {
              showToasterMessage({
                  messageType: "error",
                  description:
                      lang[((err.response || {}).data || {}).message] ||
                      "Some error occurred",
              });
          }
      
      })
      .finally(() => {

        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};