import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const changePassword = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/onlineUser/changePassword`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Your password has been reset successfully.' });
       props && props.hideModal();
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}