const configuration = {
    UI_BASE_URL: 'https://us-app.alpide.com',
    API_BASE_URL: 'https://api-us.alpidepay.com',
  PDF_API_BASE_URL: 'https://doc.alpidedev.com/api/',
    INVOICE_SERVICE_BASE_URL: 'https://api-us.alpidepay.com',
    PAYMENT_GATEWAY_BASE_URL: 'https://api-us.alpidepay.com',
    SALES_BASE_URL: 'https://api-us.alpidepay.com',
    USERS_BASE_URL: 'https://api-us.alpidepay.com',
    ACCOUNTING_BASE_URL: 'https://api-us.alpidepay.com',
    LOOKUP_BASE_URL: "https://api-us.alpidepay.com",
    EDUCATION_BASE_URL: "https://api-us.alpidepay.com",
    COMMUNICATION_BASE_URL: "https://api-us.alpidepay.com",
    API_VERSION: '',
    rootContext: 'alpide',
    rootContextSMS: 'alpide-sms',
    rootContextTwilio: 'alpide-tcom',
    rootContextInvoiceService: 'alpide-invoice',
    rootContextCC: 'alpide-cc',
    rootContextRazorpay: 'alpide-razorpay',
    rootContextStripe: 'alpide-stripe',
    rootContextHRMS: 'alpide',
    rootContextSales: 'sales',
    rootContextUsers: 'users',
    rootContextAccounting: 'accounting',
    rootContextLookup: 'lookup',
    rootContextEducation: 'education',
    rootContextLogistic: 'logistic',
    rootContextCommunication: 'communication',
    BUCKET_NAME: {
      BO_RELATIONSHIP: 'relationship-u',
    }
  };
  
  export default configuration;
