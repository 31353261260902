import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS,COMMON_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getAllStudentForm = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getAllForms?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&registrationType=student`)
            .then((res) => {
                dispatch({
                    type: SCHOOL_ACTIONS.STUDENT_FORM_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
                dispatch({ type: SCHOOL_ACTIONS.DONATION_SETTING_LOADING, data: {isStudentFormListLoaded : true}});
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            });
    };
};

export const getAllCustomerCourses = (payload={}) => {
    return (dispatch) => {
        return axios
            .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/getStudentCourses?relationshipId=${payload.relationshipId || 0}&customerId=${payload.customerId || 0}`)
            .then((res) => {
                dispatch({
                    type: SCHOOL_ACTIONS.CUSTOMER_SCHOOL_COURSE,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || {}).message] ||
                        "Some error occurred",
                });
            })
    };
};


