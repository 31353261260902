const configuration = {
  UI_BASE_URL: 'http://localhost:3001/app/',
  API_BASE_URL: 'http://localhost:8060',
  INVOICE_SERVICE_BASE_URL: 'http://localhost:8060',
  PAYMENT_GATEWAY_BASE_URL: 'http://localhost:8060',
  INVENTORY_BASE_URL: 'http://localhost:8060',
  SALES_BASE_URL: 'http://localhost:8060',
  USERS_BASE_URL: 'http://localhost:8060',
  ACCOUNTING_BASE_URL: 'http://localhost:8060',
  PURCHASE_BASE_URL: "http://localhost:8060",
  CRM_BASE_URL: "http://localhost:8060",
  LOOKUP_BASE_URL: "http://localhost:8060",
  EDUCATION_BASE_URL: "http://localhost:8060",
  // EDUCATION_BASE_URL: "http://localhost:8060",
  LOGISTIC_BASE_URL: "http://localhost:8060",
  HRMS_BASE_URL: "https://service.alpidedev.com",
  ECOM_BASE_URL: "http://localhost:8060",
  COUPON_BASE_URL: "http://localhost:8060",
  KIOSK_BASE_URL: 'http://localhost:3001',
  COMMUNICATION_BASE_URL: "http://localhost:8060",
  API_VERSION: '',
  rootContext: 'alpide',
  rootContextSMS: 'alpide-sms',
  rootContextTwilio: 'alpide-tcom',
  rootContextInvoiceService: 'alpide-invoice',
  rootContextPaymentGateway: 'alpide-paypal',
  rootContextPlaid: 'alpide-plaid',
  rootContextMWS: 'alpide-mws',
  rootContextSMS: 'alpide-sms',
  rootContextBrevo: 'brevo-sms-app',
  rootContextCC: 'alpide-cc',
  rootContextSupport: 'support',
  rootContextRazorpay: 'alpide-razorpay',
  rootContextStripe: 'alpide-stripe',
  rootContextHRMS: 'alpide',
  rootContextCMS: 'cms',
  rootContextInventory: 'inventory',
  rootContextPurchase: 'purchase',
  rootContextSales: 'sales',
  rootContextUsers: 'users',
  rootContextAccounting: 'accounting',
  rootContextLookup: 'lookup',
  rootContextCRM: 'crm',
  rootContextEducation: 'education',
  rootContextLogistic: 'logistic',
  rootContextECOM: 'commerce',
  rootContextCoupon: 'coupon',
  rootContextCommunication: 'communication',
  rootContextProject: 'project',
  BUCKET_NAME: {
    BO_RELATIONSHIP: 'relationship-u',
  }
};

export default configuration;