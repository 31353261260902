import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Empty, Tabs } from "antd";
import PageBreadcrumb from "../PageBreadcrumb";
import './index.scss'
import TableView from '../../containers/Timetable/tableView';

const {TabPane} = Tabs;


const TimeTableComp = (props) => {

  const {allStudentList} = props;

  const breadCrumbList=[
    {name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />},
    {name: <FormattedMessage id='sidebar.menuItem.timeTable' defaultMessage='' />},
    {name: <FormattedMessage id='list.text' defaultMessage='' />},
  ];

  // const getStudentClasses = (classSectionList=[], allStudentList=[]) =>{
  //   const classIdMap = {};
  //   allStudentList.forEach(item => {
  //     classIdMap[item.classId] = true;
  //   });

  //   const filteredList = classSectionList.filter(item => {
  //     return classIdMap[item.classId];
  //   });
  //   return (filteredList || []);
  // }

  // const openTimeTable = (props, data) =>{

  //   props.pushModalToStack({
  //     title: `Time Table(${data.className || ''}${data.sectionName ? `-${data.sectionName}` :  ''})`,
  //     maskClosable: true,
  //     isClosable: false,
  //     keyboard: false,
  //     modalBody: <TableView {...props} classData={data}/>,
  //     wrapClassName: "model-open-wrapper",
  //     width: '100%',
  //     hideFoote: true
  //   })

  // }

  const handleTabChange = (studentId) => {

    const student = (props.allStudentList || []).find(student => student.schoolStudentId === Number(studentId)) || {};
    props.getTimeTableByClassSection({
      relationshipId: student.relationshipId,
      classId: student.classId,
      sectionId: ((student?.schoolStudentClassRefList[0] || []).classSectionId) || 0
    });
    props.updateState({
      activeTab:studentId,
      
    })

  
  };


  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='sidebar.menuItem.timeTable' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>
      
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='table.heading.timeTableList' defaultMessage='' />
            </div>
          </div>
          <div className="right-actions">
          </div>
        </div>

       {
         (allStudentList || []).length ? 

            <Tabs
            activeKey={props.activeTab || ((props.allStudentList || []).length ?  String(props.allStudentList[0].schoolStudentId) : "")}
            onChange={handleTabChange}
            type="line"
            
            >
              {(props.allStudentList || []).map((item, i) => {
                return (
                  <TabPane
                    tab={`${item.firstName || `Student ${i}`} ${item.lastName || ''}`}
                    key={item.schoolStudentId.toString()}
                  >
                    <TableView {...props} studentData={item}
                     />
                  </TabPane>
                );
              })}
            </Tabs>
          :
          <Empty />
         } 
      </div>
    </>
  );
};

export default injectIntl(TimeTableComp);
