import axios from "axios";
import config from "../../config/environmentConfig";
import { MEMBERSHIP_ACTIONS, SCHOOL_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getAllMembrshipForm = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getAllForms?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&registrationType=membership`)
            .then((res) => {
                dispatch({
                    type: MEMBERSHIP_ACTIONS.MEMBERSHIP_FORM_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
              dispatch({ type: SCHOOL_ACTIONS.DONATION_SETTING_LOADING, data: {isStudentFormListLoaded : true}});
            });
    };
};

