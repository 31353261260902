import React from 'react'
import { Button, Empty, Skeleton } from 'antd';
import { capitalizeFirstLetter, getCurrencySymbol, getMomentDateForUIReadOnly } from '../../utils';
import config from '../../config/environmentConfig';


const PendingFees = (props) => {
    const { invoiceProformaList = [] } = props;

    const generateInvoicePDF = (data) => {
      let payload = getPayloadForInvoicePdf(data);
      props.printPDF(payload);
    }

    const getPayloadForInvoicePdf = (data) => {
      return {
        fileName: 'Invoice.pdf',
        templateName: "Receipt", 
        transactionName: 'Receipt',
        companyInfo:props.companyInfo,
        invoiceData:data

      }
    }
  return <div className="fee-card-container">
    <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
      {invoiceProformaList?.length ? 
        invoiceProformaList.map((item, index) => (
          <div key={index} className="fee-card">
            <div className="fee-card-content">
                <div className="card-header">Fees Installement {index+1}</div>
                <div className="fee-card-row">
                  <div className='row-item'>
                    <span className="label">Amount</span>
                    <span className="value">{getCurrencySymbol(item?.currencyCode)}{(item.invoiceTotalAmount || 0).toFixed(2)}</span>
                  </div>
                  <div className='row-item'>
                    <span className="label">Due Date</span>
                    <span className="value">{item.expiryDate ? getMomentDateForUIReadOnly({
                      date: item.expiryDate
                    }) : '-'}</span>
                  </div>

                  <div className='row-item'>
                    <span className="label">Status</span>
                    <span className="value">
                      {item.paymentStatus ?
                        <div className={`status-label ${item.paymentStatus}`}>{capitalizeFirstLetter(item.paymentStatus)}</div> 
                        : 
                        <div className='status-label unpaid'>{'Unpaid'}</div> 
                      }
                    </span>
                  </div>

                  {item.paymentStatus === 'paid' && item.isInvoiceConverted ?
                    <Button 
                      className='cus-btn mt10'
                      style={{height:"32px"}}
                      onClick={()=>{
                        props.getInvoiceByProformaId({
                          relationshipId:item.relationshipId,
                          proformaId:item.invoiceProformaId,
                          callBack:(data)=>{
                            generateInvoicePDF(data)}
                        })
                        props.updateState({
                          proformaId:item.proformaId
                        })
                      }}
                    >
                      Download Invoice
                    </Button>
                    :
                    <Button 
                      type='primary'
                      className='mt10'
                      style={{height: '32px'}}
                      onClick={()=>{
                        window.open(`${config.UI_BASE_URL}/proforma-payment?${encodeURIComponent(`rid=${item.relationshipId}&proformaId=${item.invoiceProformaId || 0}&customerId=${props.customerInfo?.customerId || 0}`)}`, '_blank');
                      }}
                    >
                      Pay Now
                    </Button>
                  }
                </div>
            </div>
          </div>
        ))
        :
        <Empty />
      }
    </Skeleton>
    </div>
}

export default PendingFees;